// 1. for clicking invitation link
// 2. for self-registration
(function() {
    angular.module('EntrakV5').controller('activationController', activationController);

    function activationController($scope, $rootScope, $timeout, Service, Api, KEY, $state, $stateParams) {
        console.log('activationController');

        $scope.passwordPlaceHolder = Service.translate('login.password');
        $scope.fNamePlaceHolder = Service.translate('login.fName');
        $scope.lNamePlaceHolder = Service.translate('login.lName');

        const email = decodeURIComponent($stateParams.email);
        const token = decodeURIComponent($stateParams.token);

        $scope.isActivationPage = true;
        $scope.isSecondPage = false;
        $scope.isActivationDone = false;

        $scope.showRules = false;
        $scope.isAllValid = false;
        $scope.rules = null;
        Service.formPost("auth/pwdpolicy", { email })
            .then(Service.parseJson)
            .then(res => {
              $scope.$apply(function(){
                $scope.showRules = true;
                $scope.rules = [];
                if (res.policies) {
                  res.policies.forEach(policy => {
                    if (policy.required) {
                      if (policy.name === "length") {
                        let lbl = policy.max && policy.max >= policy.min ? Service.translate("lbl.pwdMinMax", policy) : Service.translate("lbl.pwdMin", policy);
                        $scope.rules.push({ lbl, isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one number") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdNumber"), isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one upper case") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdUpperCase"), isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one lower case") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdLowerCase"), isValid: false, exp: new RegExp(policy.rule) });
                      } else if (policy.name === "at least one special char") {
                        $scope.rules.push({ lbl: Service.translate("lbl.pwdSpecialChar"), isValid: false, exp: new RegExp(policy.rule) });
                      } else {
                        
                      }
                    }
                  });
                }
              });
            })
            .catch(err => {
              $scope.$apply(function(){
                console.error("err", err);
                $scope.errMsg = Service.translate("error.generalActivateFail");
              });
            });

        $scope.handleValidation = function(){
          $scope.rules.forEach(r => {
            r.isValid = r.exp.test($scope.activationData.pwd);
          });
          $scope.isAllValid = $scope.rules.find(r => !r.isValid) == null;
        }

        $scope.activationData = {
            pwd: "",
            fName: "",
            lName: "",
            email: email,
            showPwd: false,
            loading: false
        }
        $scope.activate = function(){
        	$scope.errMsg = null;
          $scope.activationData.loading = true;
          Service.formPost("auth/invite_confirm", { email, token, password: $scope.activationData.pwd, first_name: $scope.activationData.fName, last_name: $scope.activationData.lName })
            .then(Service.parseJson)
            .then(r => {
              $scope.$apply(function(){
                if (r.errno){
                  if (r.errno == 401) {
                    $scope.errMsg = Service.translate('error.emptyName');
                  } else if (r.errno == 400 || r.errno == 402 || r.errno == 403) {
                    $scope.errMsg = Service.translate("error.activationLinkInvalid");
                  } else if (r.errno == 404 || r.errno == 406) {
                    $scope.errMsg = Service.translate("error.pwdFormatInvalid");
                  } else if (r.errno == 409) {
                    $scope.errMsg = Service.translate("error.activatedAlready");
                  } else {
                    $scope.errMsg = Service.translate("error.generalActivateFail");
                  }
                  console.log("activate error:", r.errno, r.errmsg);
                } else {
                  console.log("activate success");
                  $scope.isActivationPage = false;
                  $scope.isActivationDone = true;
                }
                $scope.activationData.loading = false;
              });
            })
            .catch(e => {
              $scope.$apply(function(){
                console.error(e);
                $scope.errMsg = Service.translate("error.generalActivateFail");
                $scope.activationData.loading = false;
              });
            });
        }

        $scope.backToSignIn = function(){
          $state.go("login", { landlord: null });
        }

        $scope.$on('$destroy', function() {
            console.log("activationController destroy");
        });
    }
})();
